import React from 'react';
import * as Variable from '../../shared/utils/variables'
const ENV_NAME = process.env.REACT_APP_ENV;

const LoadingComponent = () => {
    return (
        <div className="container loading-component">
            <div className="row vh-100 align-items-center">
                <div className="col d-flex flex-column align-items-center">
					<div className="spinner-border m-2" role="status">
						<span className="sr-only"></span>
					</div>
					<div className="h5">{Variable.LOADING[ENV_NAME]}</div>
				</div>
            </div>
        </div>
    );
};
export default LoadingComponent;
