import * as actionTypes from "../actions/actionTypes";
const initialState = {
	isAuthenticated: false,
	userDetails: null,
	selectedEnvironment: "bhs",
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_LANGUAGE: {
			return {
				...state,
				selectedEnvironment: action.payload,
			};
		}
		default:
			return state;
	}
};
export default reducer;
