import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import createMiddleware from "redux-saga";
import App from "./App";
import rootReducer from "./store/reducers/index";
import { rootSaga } from "./store/sagas/index";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { homeUrl } from "./shared/utils/apiEndPoints";

import "./index.css";
import "./style.scss";

Sentry.init({
	dsn:
		homeUrl === "portalsekolah.co.id"
			? "https://126c65450e3042eb9c4410316dae79aa@sentry.portalsiswa.id/16"
			: homeUrl === "promisedland.id"
			? "https://bdef12a5ac7b4fc08746800c0affa019@sentry.portalsiswa.id/25"
			: "https://030c77dc297b4c4e90087c7fd2511293@sentry.portalsiswa.id/7",
	integrations: [new BrowserTracing()],
	tracesSampleRate: homeUrl === "portalsekolah.co.id" ? 0.2 : 1.0,
	beforeSend: (event) => {
		if (window.location.hostname === "localhost") {
			return null;
		}
		return event;
	},
});

if (window.location.hostname === "portalsekolah.co.id") {
	const tagManagerArgs = {
		gtmId: "GTM-PPQ699M",
	};
	TagManager.initialize(tagManagerArgs);
}
const sagaMiddleware = createMiddleware();
ReactDOM.render(
	<Provider store={createStore(rootReducer, applyMiddleware(sagaMiddleware))}>
		<App />
	</Provider>,
	document.getElementById("root")
);
sagaMiddleware.run(rootSaga);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
