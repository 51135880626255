export const ROOT = "/";
export const HOME = "/home";
export const DASHBOARD = "/dashboard";
export const TNC = "/tnc";
export const PRIVACY_POLICY = "/privacy-policy";
export const HELP = "/help";
export const SIGNIN = "/login";
export const SIGNUP = "/signup";
export const FORGOTPASSWORD = "/forgot-password";
export const FORGOTPASSWORDMETHOD = "/forgot-password-method";
export const SUCCESS_SENT_EMAIL_LINK = "/success-sent-email";
export const RESETPASSWORD = "/verify-reset-password";
export const VERIFYUSER = "/verify-user";
export const ABOUT = "/about";
export const LMS = "/learning-management-system";
export const SMS = "/school-management-system";
export const CONTACT_US = "/contact-us";
