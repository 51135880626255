export const t = (key, env, text) => {
	if (key == null || typeof key !== "object") {
		key == null && console.error("translation not found", text);
		return text || "--";
	}
	return key[env];
};

export const FILL_ALL_DETAILS_CORRECTLY = {
	eng: "Fill details correctly",
	bhs: "Isi detail dengan benar",
};
export const ERROR_INVALID_LOGIN = {
	eng: "Login or password invalid",
	bhs: "Nama Pengguna atau Kata Sandi Salah",
};
export const SUCCESSFULLY_RESET_PASSWORD = {
	eng: "Password successfully reset",
	bhs: "Password berhasil diupdate"
}
export const TOAST_SESSION_EXPIRED = {
	eng: "Session Expired",
	bhs: "Sesi berakhir",
};
export const FILL_CORRECT_PASSWORD = {
	eng: "Password Empty",
	bhs: "Kata Sandi kosong",
};
export const FILL_CORRECT_USER_ID = {
	eng: "User Name Empty",
	bhs: "Nama Pengguna kosong",
};
export const FILL_CORRECT_USER_ID_PASSWORD = {
	eng: "User Name & Password Empty",
	bhs: "Nama Pengguna & Kata Sandi kosong",
};
export const ERROR_INTERNET_CONNECTION = {
	eng: "Your Connection Is Lost",
	bhs: "Koneksi Anda Terputus",
};
export const ALREADY_HAVE_ACCOUNT = {
	eng: "Already have an account?",
	bhs: "Sudah punya akun?",
};
export const ENTER_USER_NAME = {
	eng: "Enter User Name",
	bhs: "Masukkan Nama pengguna",
};
export const USER_NAME = {
	eng: "User Name",
	bhs: "Nama pengguna",
};
export const GRADE = {
	eng: "Grade",
	bhs: "Jenjang Kelas",
};
export const SELECT_GRADE = {
	eng: "Select Grade",
	bhs: "Pilih Jenjang Kelas",
};
export const KINDLY_SELECT_GRADE = {
	eng: "Kindly Select Grade",
	bhs: "Silahkan Pilih Jenjang Kelas",
};
export const SELECT_SCHOOL = {
	eng: "Select School",
	bhs: "Pilih Sekolah",
};
export const KINDLY_SELECT_SCHOOL = {
	eng: "Kindly Select School",
	bhs: "Silahkan Pilih Sekolah",
};
export const REGISTER = {
	eng: "Register",
	bhs: "Registrasi",
};

// ForgotPasswordComponent
export const FORGOT_PASSWORD = {
	eng: "Forgot Password",
	bhs: "Lupa Kata Sandi",
};
export const FORGOT_PASSWORD_MESSAGE = {
	eng: "Enter your email address to receive a reset link",
	bhs: "Masukkan alamat email Anda untuk menerima tautan reset",
};
export const FORGOT_PASSWORD_SA_MESSAGE = {
	eng: "Please enter your username",
	bhs: "Silahkan masukkan nama pengguna Anda",
};

export const FORGOT_PASSWORD_METHOD = {
	eng: "How do you want to reset your password?",
	bhs: "Bagaimana Anda ingin mengatur ulang kata sandi?",
};

export const SCHOOL_ADMIN = {
	eng: "School Admin",
	bhs: "Admin Sekolah",
};

export const NEXT = {
	eng: "Next",
	bhs: "Berikutnya",
};

export const FORGOT_PASSWORD_MAIL_SENT = {
	eng: "We have sent a confirmation email. please check your email to reset your password",
	bhs: "Kami telah mengirimkan email konfirmasi. silakan cek email Anda untuk mengatur ulang kata sandi Anda",
};
export const FORGOT_PASSWORD_SA_NOTIFIIED = {
	eng: "We have notified your school administratior to reset your password. Your administrator will let you know once your password has been reset",
	bhs: "Kami telah memberitahu administrator sekolah Anda untuk mengatur ulang kata sandi Anda. Administrator akan memberitahu Anda ketika kata sandi Anda telah diatur ulang",
};
export const FORGOT_PASSWORD_MAIL_SENT_SHORT = {
	eng: "Confirmation Email Sent!",
	bhs: "Email konfirmasi terkirim!",
};

export const FORGOT_PASSWORD_SA_NOTIFIED_SHORT = {
	eng: "Admin has been notified",
	bhs: "Admin telah diberitahu",
};

export const HAVENT_GOT_EMAIL = {
	eng: "Haven't got e-mail?",
	bhs: "Belum mendapatkan e-mail?",
};
export const RESENT = {
	eng: "Resent",
	bhs: "Kirim Ulang",
};
export const BACK_TO_LOGIN = {
	eng: "Back To Login",
	bhs: "Kembali untuk masuk",
};
export const SEARCH_ERROR = {
	eng: "Minimum 2 letters required for search",
	bhs: "Minimal 2 huruf untuk mencari",
};
export const DICTIONARY_SEARCH_PLACEHOLDER = {
	eng: "Type word to search",
	bhs: "Ketikkan kata untuk mencari",
};
export const LOADING = {
	eng: "Loading",
	bhs: "Memuat",
};
export const DICTIONARY_SEARCH_ERROR = {
	eng: "No similar word found.",
	bhs: "Tidak ada kata yang sama ditemukan.",
};
export const SEARCH_WORD_HERE = {
	eng: "Search words here",
	bhs: "Cari kata disini",
};
export const PAGE = {
	eng: "Page",
	bhs: "Halaman",
};
export const OF = {
	eng: "of",
	bhs: "dari",
};
export const CONFIRM_PASSWORD = {
	eng: "Confirm Password",
	bhs: "Konfirmasi Kata Sandi",
};

export const EMAIL = {
	eng: "Email",
	bhs: "Email",
};
export const ENTER_EMAIL = {
	eng: "Enter Email",
	bhs: "Masukkan Email",
};

export const FIRST_NAME = {
	eng: "First Name",
	bhs: "Nama Depan",
};
export const ENTER_FIRST_NAME = {
	eng: "Enter First Name",
	bhs: "Masukkan Nama Depan",
};
export const LAST_NAME = {
	eng: "Last Name",
	bhs: "Nama Belakang",
};
export const ENTER_LAST_NAME = {
	eng: "Enter Last Name",
	bhs: "Masukkan Nama Belakang",
};
export const PASSWORD = {
	eng: "Password",
	bhs: "Kata Sandi",
};
export const SIGNIN = {
	eng: "Sign In",
	bhs: "Masuk",
};

export const CONTINUE = {
	eng: "Continue",
	bhs: "Terus",
};

// ResetPasswordComponent
export const ENTER_CONFIRM_PASSWORD = {
	eng: "Enter Confirm Password",
	bhs: "Masukkan Konfirmasi Kata Sandi",
};
export const ENTER_PASSWORD = {
	eng: "Enter Password",
	bhs: "Masukkan Kata Sandi",
};
export const ENTER_SIMILAR_PASSWORD = {
	eng: "Enter similar password",
	bhs: "Masukkan Kata Sandi yang Sama",
};
export const FILL_CORRECT_EMAIL_ID = {
	eng: "Fill correct Email Id",
	bhs: "Masukkan Email yang Benar",
};
export const GO_TO_LOGIN = {
	eng: "Back to Home",
	bhs: "Kembali ke Halaman Utama",
};
export const NEW_PASSWORD = {
	eng: "New Password",
	bhs: "Kata Sandi Baru",
};
export const PASSWORD_CHARACTER_ERROR = {
	eng: "Password should have atleast 8 characters",
	bhs: "Kata sandi harus memiliki minimal 8 karakter",
};
export const PASSWORD_NOT_MATCHED = {
	eng: "password Does not matched",
	bhs: "Kata Sandi tidak Cocok",
};
export const RESET_PASSWORD = {
	eng: "Create New Password",
	bhs: "Atur Ulang Kata Sandi",
};
export const RESET_PASSWORD_SUCCESSFULL = {
	eng: "Your password has been reset successfully. Please continue to login.",
	bhs: "Kata sandi Anda berhasil disetel ulang. Silahkan lanjutkan untuk masuk.",
};
export const SET_YOUR_NEW_PASSWORD = {
	eng: "Set New Password",
	bhs: "Atur Kata Sandi Baru",
};

export const ENTER = {
	eng: "Enter",
	bhs: "Masukkan",
};

export const SCHOOL = {
	eng: "School",
	bhs: "Sekolah",
};

export const FILL_USER_DETAILS = {
	eng: "Please fill user details",
	bhs: "Silakan isi detail pengguna",
};

export const SEND_EMAIL = {
	eng: "Send Email",
	bhs: "Kirim Email",
};
export const SEND = {
	eng: "Send",
	bhs: "Kirim",
};
export const USER_ID = {
	eng: "User Id",
	bhs: "Nama Pengguna",
};

export const STUDENT = {
	eng: "Student",
	bhs: "Murid",
};

export const TEACHER = {
	eng: "Teacher",
	bhs: "Guru",
};

export const MOBILE_NUMBER = {
	eng: "Mobile Number",
	bhs: "",
};

export const ENTER_MOBILE_NUMBER = {
	eng: "Enter Mobile Number",
	bhs: "",
};
export const PROFILE_PICTURE = {
	eng: "Profile Picture",
	bhs: "",
};
export const SELECT_PROFILE_PICTURE = {
	eng: "Select Profile Picture",
	bhs: "",
};

// Landing page content
export const HOME = {
	eng: "Home",
	bhs: "Beranda",
};
export const SLIDER_1_HEADING = {
	eng: "The Best Solution for All Education Elements",
	bhs: "Solusi Terbaik untuk Seluruh Elemen Pendidikan",
};
export const HALO_REPLACE = {
	eng: "Hello {0}!",
	bhs: "Halo {0}!",
};
export const SLIDER_1_CUSTOM = {
	eng: "Welcome in Portal Sekolah, a digital school system that helps all of elements of education of {0} to achieve the optimal results in organizing education.",
	bhs: "Selamat datang di Portal Sekolah, sistem sekolah digital yang membantu seluruh elemen pendidikan {0} untuk mencapai hasil optimal dalam menyelenggarakan pendidikan.",
};

export const SLIDER_1_DESC = {
	eng: "Portal Sekolah helps students, teachers, principals, parents, administrators, and committee members to achieve optimal results in fulfilling their responsibilities",
	bhs: "Portal Sekolah membantu siswa, guru, kepala sekolah, orang tua, administrator, dan anggota komite sekolah untuk mencapai hasil optimal dalam memenuhi kewajiban mereka.",
};

export const SLIDER_2_HEADING = {
	eng: "Integrated Digital School System",
	bhs: "Sistem Sekolah Digital Terpadu",
};
export const SLIDER_2_DESC = {
	eng: "Creating quality education in the era of industry 4.0",
	bhs: "Portal Sekolah menciptakan pendidikan berkualitas di era industri 4.0.",
};
export const SLIDER_2_CUSTOM = {
	eng: "The School Portal supports quality education in the industrial era 4.0 in {0}",
	bhs: "Portal Sekolah mendukung pendidikan berkualitas di era industri 4.0 di {0}.",
};

export const SLIDER_3_HEADING = {
	eng: "Easy-to-Use Platform",
	bhs: "Platform yang Mudah Digunakan",
};
export const SLIDER_3_DESC = {
	eng: "Portal Sekolah provides user-friendly features that support all school management needs",
	bhs: "Portal Sekolah memberikan fitur-fitur ramah pengguna yang mendukung seluruh kebutuhan belajar mengajar serta manajemen sekolah.",
};

export const OVERVIEW_TAGLINE = {
	eng: "We provide digital school system",
	bhs: "Sistem Sekolah Digital Terpadu",
};
export const OVERVIEW_TAGLINE_CUSTOM = {
	eng: "We provide digital school system for {0}",
	bhs: "Sistem Sekolah Digital Terpadu untuk {0}",
};
export const OVERVIEW_DESC = {
	eng: "Portal Sekolah is a platform that transforms conventional school system into digital school system, which is the accurate solution to be used not only for remote learning, but also face-to-face learning at school.",
	bhs: "Portal Sekolah merupakan sebuah platform yang mentransformasi sistem sekolah konvensional ke sistem digital, yang menjadi solusi tepat untuk digunakan tidak hanya pada saat Pembelajaran Jarak Jauh (PJJ), namun juga pada saat Pembelajaran Tatap Muka (PTM) di sekolah.",
};
export const OVERVIEW_DESC_CUSTOM = {
	eng: "Portal Sekolah is a platform that transforms conventional school system into digital school system, which is the accurate solution for {0}.",
	bhs: "Portal Sekolah merupakan sebuah platform yang mentransformasi sistem sekolah konvensional ke sistem digital, yang menjadi solusi tepat untuk {0}.",
};

export const OVERVIEW_POINT_1 = {
	eng: "Learning made easy and fun",
	bhs: "Belajar menjadi mudah dan menyenangkan",
};
export const OVERVIEW_POINT_1_DESC = {
	eng: "Watch animated videos, study modules by topic, complete assignments, and many more",
	bhs: "Tonton video animasi, belajar modul per topik, kerjakan tugas, dan masih banyak lagi",
};
export const OVERVIEW_POINT_2 = {
	eng: "Integrated with School Activities",
	bhs: "Terintegrasi dengan aktivitas sekolah",
};
export const OVERVIEW_POINT_2_DESC = {
	eng: "Check activities schedule, attend online classes, and read announcements",
	bhs: "Periksa jadwal kegiatan, hadiri kelas daring, dan baca pengumuman",
};
export const OVERVIEW_POINT_3 = {
	eng: "Assignment and Exam Notification",
	bhs: "Notifikasi tugas dan ujian",
};
export const OVERVIEW_POINT_3_DESC = {
	eng: "No more missed assignments and be better prepared for exams",
	bhs: "Tidak ada lagi tugas yang terlewat dan menjadi lebih siap untuk ujian",
};

export const ADMIN = {
	eng: "Admin",
	bhs: "Admin",
};
export const ADMIN_DESC = {
	eng: "We optimize principal’s performance of administrative, supervisory, and managerial duties. It’s school management made simple.",
	bhs: "Kami mengoptimasi performa kepala sekolah dalam menyelesaikan tugas administratif, pengawasan, dan manajerial. Manajemen sekolah menjadi sederhana.",
};

export const TEACHER_DESC = {
	eng: "We optimize principal’s performance of administrative, supervisory, and managerial duties. It’s school management made simple.",
	bhs: "Kami memberdayakan guru dalam menjalankan peran mereka sebagai pendidik dan motivator bagi siswa. Guru dapat melakukan tugas akademik secara efektif dan efisien.",
};

export const STUDENT_DESC = {
	eng: "We help students to achieve effective learning with optimal results. With Portal Sekolah, students can access structured and complete high quality materials easily.",
	bhs: "Kami membantu siswa mencapai pembelajaran efektif dengan hasil yang maksimal. Dengan Portal Sekolah, siswa dapat mengakses materi yang terstruktur dan lengkap dengan mudah.",
};

export const PARENTS = {
	eng: "Parents",
	bhs: "Orang tua",
};
export const PARENTS_DESC = {
	eng: "We provide parents with ease in overseeing their children’s learning activities, and academic progress.",
	bhs: "Kami menyediakan kemudahan bagi orang tua dalam memonitor kegiatan belajar anak mereka, termasuk melihat rapor digital, laporan kehadiran, maupun pencapaian akademik.",
};
export const SCHOOL_OFFICIALS_MAIN_HEADING = {
	eng: "School Officials",
	bhs: "Dinas Pendidikan/Yayasan",
};
export const SCHOOL_OFFICIALS_MAIN_DESC = {
	eng: "We aid School Officials in overseeing the progress of the schools under their supervision.",
	bhs: "Kami membantu pihak Dinas Pendidikan/Yayasan dalam memantau perkembangan sekolah di bawah naungannya.",
};
export const SCHOOL_OFFICIALS_CUSTOM_HEADING = {
	eng: "School Officials",
	bhs: "Dinas Pendidikan",
};
export const SCHOOL_OFFICIALS_CUSTOM_DESC = {
	eng: "We aid School Officials in overseeing the progress of the schools under their supervision.",
	bhs: "Kami membantu pihak Dinas Pendidikan dalam memantau perkembangan sekolah di bawah naungannya.",
};
export const PRODUCTS_FEATURES = {
	eng: "Products & Features",
	bhs: "Produk & Fitur",
};
export const PRODUCTS_FEATURES_DESC = {
	eng: "Manage all school activities with only one integrated digital school system",
	bhs: "Kelola seluruh kegiatan sekolah dengan sistem sekolah digital tunggal yang terintegrasi",
};

export const ASSESSMENT = {
	eng: "Assessment",
	bhs: "Penilaian",
};
export const ASSESSMENT_DESC = {
	eng: "Assessment feature gives the ease of creating and doing tests, exams, and assignments.",
	bhs: "Fitur Penilaian memberikan kemudahan dalam membuat dan mengerjakan tes, ujian, dan tugas.",
};
export const GRADEBOOK = {
	eng: "Gradebook",
	bhs: "Buku Nilai",
};
export const GRADEBOOK_DESC = {
	eng: "Gradebook shows complete and automatic student grades data.",
	bhs: "Buku Nilai menyajikan data nilai siswa secara lengkap dan otomatis.",
};
export const WORKBOOK = {
	eng: "Workbook",
	bhs: "Buku Kerja",
};
export const WORKBOOK_DESC = {
	eng: "Workbook helps teachers, principals, and school admins in completing administrative tasks.",
	bhs: "Buku Kerja membantu guru, kepala sekolah, dan administrator sekolah dalam memenuhi tugas administratif sekolah.",
};
export const USER_MGMT = {
	eng: "User Management",
	bhs: "Manajemen Pengguna",
};
export const USER_MGMT_DESC = {
	eng: "Manage classes and users with ease and flexibility.",
	bhs: "Kelola kelas dan pengguna dengan mudah dan fleksibel.",
};
export const VIDEO_CNF = {
	eng: "Video Conference",
	bhs: "Konferensi Video",
};
export const VIDEO_CNF_DESC = {
	eng: "Meetings are made easy with Portal Sekolah SMART Video Conference.",
	bhs: "Pertemuan daring di sekolah menjadi mudah dengan Portal Sekolah SMART Video Conference",
};
export const CALENDAR = {
	eng: "Calendar",
	bhs: "Kalender",
};
export const CALENDAR_DESC = {
	eng: "Users can schedule personal or school events and activities.",
	bhs: "Pengguna dapat menjadwalkan acara dan kegiatan pribadi maupun sekolah.",
};
export const NOTE_TAKING = {
	eng: "Note-Taking",
	bhs: "Catatan",
};
export const NOTE_TAKING_DESC = {
	eng: "All users can enjoy the ease of taking notes and categorizing them.",
	bhs: "Semua pengguna dapat menikmati keleluasaan membuat dan mengorganisasikan catatan.",
};
export const ANNOUNCEMENT = {
	eng: "Announcement",
	bhs: "Pengumuman",
};
export const ANNOUNCEMENT_DESC = {
	eng: "Never miss any important information published by school principals or teachers.",
	bhs: "Tidak ada lagi informasi penting dari kepala sekolah atau guru yang terlewatkan.",
};
export const VIRTUAL_LAB = {
	eng: "Virtaul Lab",
	bhs: "Lab Maya",
};
export const VIRTUAL_LAB_DESC = {
	eng: "Lab experiments are accessible to every user.",
	bhs: "Eksperimen laboratorium dapat diakses oleh setiap pengguna.",
};
export const QUESTION_BANK = {
	eng: "Question Bank",
	bhs: "Bank Soal",
};
export const QUESTION_BANK_DESC = {
	eng: "Complete question bank for every subject in every grade.",
	bhs: "Bank soal yang lengkap untuk setiap mata pelajaran di setiap jenjang.",
};
export const REPORTS = {
	eng: "Reports",
	bhs: "Laporan",
};
export const REPORTS_DESC = {
	eng: "Access digital reports including ledger, attendance, and score reports.",
	bhs: "Akses laporan termasuk leger, laporan kehadiran, serta rapor digital.",
};
export const SUBJECTS = {
	eng: "Subjects",
	bhs: "Mata Pelajaran",
};
export const SUBJECTS_DESC = {
	eng: "Complete learning materials, including electronic text book (BSE) is provided structurally.",
	bhs: "Materi pembelajaran yang lengkap, termasuk Buku Sekolah Elektronik (BSE) tersedia secara terstruktur.",
};

export const VIDEO_HEAD_1 = {
	eng: "A Solution",
	bhs: "Solusi",
};

export const VIDEO_HEAD_2 = {
	eng: "for Every School",
	bhs: "untuk Setiap Sekolah",
};

export const VIDEO_TAGLINE = {
	eng: "Portal Sekolah optimizes teaching and learning activities as well as the performance of administrative, supervisory, and managerial duties.",
	bhs: "Portal Sekolah mengoptimasi kegiatan belajar mengajar dan performa tugas-tugas administratif, pengawasan, dan manajerial.",
};
export const VIDEO_DESC = {
	eng: "As a learning management as well as school management system, our powerful platform is easily accessible for all educational elements. Not only will users benefit from Portal Sekolah during distance or hybrid learning, but also in a face-to-face school setting as it provides students, teachers, and school management with complete features and content – all for better education in the era of industry 4.0.",
	bhs: "Sebagai sebuah sistem manajemen sekolah dan pembelajaran, platform kami yang mutakhir dapat diakses dengan mudah oleh seluruh elemen pendidikan. Pengguna tidak hanya dapat merasakan manfaat dari Portal Sekolah selama pembelajaran jarak jauh atau hybrid, tetapi juga selama pembelajaran tatap muka di sekolah,karena Portal Sekolah menyediakan fitur dan konten yang lengkap bagi siswa, guru, dan manajemen sekolah untuk pendidikan yang lebih baik di era industri 4.0.",
};
export const SERVICES = {
	eng: "Our Services",
	bhs: "Layanan Kami",
};
export const SERVICES_TAGLINE = {
	eng: "We provide support and services for all users to create a smooth experience in using Portal Sekolah",
	bhs: "Kami menyediakan dukungan dan layanan untuk seluruh pengguna untuk menciptakan pengalaman yang lancar dalam menggunakan Portal Sekolah.",
};
export const DATA_IMPORT = {
	eng: "Data Import",
	bhs: "Impor Data",
};
export const DATA_IMPORT_DESC = {
	eng: "Portal Sekolah provides free data migration and system setting services for all users.",
	bhs: "Portal sekolah memberikan layanan migrasi data dan pengaturan sistem secara gratis bagi para pengguna.",
};

export const TRAINING = {
	eng: "Training",
	bhs: "Pelatihan",
};
export const TRAINING_DESC = {
	eng: "Portal Sekolah provides application training for all users (students, teachers, and administrator). All users will also be provided with manuals and tutorial videos.",
	bhs: "Portal Sekolah memberikan pelatihan kepada semua pengguna, serta membekali pengguna dengan manual dan video tutorial.",
};

export const CUSTOMER_SUPPORT = {
	eng: "Customer Support",
	bhs: "Layanan Pelanggan",
};
export const CUSTOMER_SUPPORT_DESC = {
	eng: "Portal Sekolah provides solution to every issue faced by user by phone, WhatsApp, email, or meetings.",
	bhs: "Portal Sekolah memberikan solusi untuk setiap permasalahan yang dialami pengguna melalui WhatsApp, email, atau pertemuan.",
};

export const DOWNLOAD = {
	eng: "Get the learning app",
	bhs: "Dapatkan aplikasi belajarnya",
};
export const DOWNLOAD_TAGLINE = {
	eng: "Portal Sekolah is available on Google Play. Install now for ease of access through your phone.",
	bhs: "Portal Sekolah tersedia di Google Play. Instal sekarang untuk kemudahan akses melalui smartphone.",
};
export const SP_APP = {
	eng: "Student & Parent Android App",
	bhs: "Aplikasi Android untuk Siswa & Orang Tua",
};
export const SF_APP = {
	eng: "Staff Android App",
	bhs: "Aplikasi Android untuk Guru & Admin",
};

export const CONTACT_US = {
	eng: "Contact Us",
	bhs: "Hubungi Kami",
};

export const ABOUT_US = {
	eng: "About Us",
	bhs: "Tentang Kami",
};

export const ASK_MORE = {
	eng: "Ask more",
	bhs: "Tanya lebih lanjut",
};

export const PRIVACY_POLICY = {
	eng: "Privacy Policy",
	bhs: "Kebijakan Privasi",
};
export const TNC = {
	eng: "Terms & Conditions",
	bhs: "Syarat dan Ketentuan",
};

export const MAIL_TO_SUBJECT = {
	eng: "Inquiry / Complaint",
	bhs: "Pertanyaan / Keluhan",
};

export const GET_APP = {
	eng: "Get the Android App",
	bhs: "Dapatkan Aplikasi Android",
};

export const REQUEST_ALREADY_SENT = {
	eng: "Request already sent",
	bhs: "Permintaan telah dikirim sebelumnya",
};

export const PASSWORD_CHANGE = {
	eng:"Password Change",
	bhs:"Ganti Password"
}

// Verification
export const ERROR_LINK_VERIFICATION = {
	eng:"Verification email failed. Link is invalid.",
	bhs:"Verifikasi email tidak berhasil. Terdapat kesalahan pada link. "
}
export const SOMETHING_WENT_WRONG = {
	eng: "Something went wrong.",
	bhs: "Terjadi kesalahan."
}
export const DELETED_ACCOUNT_LABEL = {
	eng: "Your account has been deleted",
	bhs: "Akun kamu sudah dihapus"
}
export const USERNAME_VALIDATION_FILLED = {
	eng: "Username wajib diisi",
	bhs: "Username wajib diisi"
}
export const USERNAME_NOT_FOUND = {
	eng: "Username not found",
	bhs: "Username tidak ditemukan"
}
export const NEW_PASSWORD_FIELD = {
	eng: "Password baru wajib diisi",
	bhs: "Password baru wajib diisi"
}
export const CONFIRM_PASSWORD_FIELD = {
	eng: "Password konfirmasi wajib diisi",
	bhs: "Password konfirmasi wajib diisi"
}