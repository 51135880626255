import React, { useEffect } from "react";
import Router from "./Route";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { homeUrl } from "./shared/utils/apiEndPoints";
import ReactGa from "react-ga";
toast.configure({
	draggable: false,
	hideProgressBar: true,
	position: toast.POSITION.TOP_RIGHT,
});
function App() {
	useEffect(() => {
		if (homeUrl === "portalsekolah.co.id") ReactGa.initialize("UA-220083682-1");
	}, []);
	return (
		<React.Fragment>
			<Router />
			<ToastContainer />
		</React.Fragment>
	);
}

export default App;
